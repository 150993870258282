import React from 'react';
import { footerContent } from '../../../content';
import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__top">
        <nav className="footer__top_block">
          <ul className="footer__nav">
            {footerContent.items.map((item, i) => (
              <li className="footer__nav-item" key={i}>
                <a
                  className="footer__nav-link"
                  href={item.link}
                  dangerouslySetInnerHTML={{ __html: item.name }}
                />
              </li>
            ))}
          </ul>
          <div className="footer__top_block_logo">
            <img src={footerContent.logo} alt="Logo" />
          </div>
        </nav>
      </div>
      <div className="footer__bottom">
        <div className="footer__bottom_block">
          <div>
            Всё об&nbsp;овощах, вкусной и&nbsp;здоровой пище и&nbsp;других
            полезных вещах.
            <br />
            Приветствуется копирование и&nbsp;размещение материалов при условии
            сохранения ссылки на&nbsp;наш сайт.
          </div>
          <div className="footer__bottom_block_copyright">
            &copy;&nbsp;Bonduelle, 2020
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
