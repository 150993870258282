import React from 'react';
import Slider from 'react-slick';
import './Slider.scss';

class SliderBlock extends React.Component {
  constructor(props) {
    super(props);
    this.slider1 = React.createRef();
    this.slider2 = React.createRef();
    // this.dots = React.createRef();

    this.content = this.props.content;
    this.history = this.props.history;
    this.path = this.props.location.pathname;
    this.pages = this.props.content.slides.map((item) => item.name);
    this.headers = {};

    this.props.content.slides.forEach((item) => {
      this.headers[item.name] = item.header;
    });

    this.state = {
      current: null,
      vw: Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ),
      nav1: null,
      nav2: null,
      arrowsAreBlocked: ''
    };

    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.onSlideChange = this.onSlideChange.bind(this);
    this.historyListen = this.historyListen.bind(this);
    //this.toggleArrowsBlock = this.toggleArrowsBlock(this);
  }

  componentDidMount() {
    this.setState({
      current: document
        .querySelector('.slick-center .slider-block__image')
        .getAttribute('data-page'),
      nav1: this.slider1.current,
      nav2: this.slider2.current
    });

    this.historyListen();
  }

  onSlideChange() {
    const newPage = document
      .querySelector('.slick-center .slider-block__image')
      .getAttribute('data-page');
    this.setState({
      current: newPage
    });

    setTimeout(() => this.history.push(`/${newPage}`), 500);
  }

  historyListen() {
    this.history.listen(() => {
      const currentPath = this.props.history.location.pathname;
      const currentPage = this.state.current;
      const slideNumber = this.pages.indexOf(currentPath.replace(/\//g, ''));

      if (currentPage && `/${currentPage}` !== currentPath) {
        this.setState({ current: currentPath.replace(/\//g, '') });
        this.state.nav1.slickGoTo(slideNumber);
      }
    });
  }

  toggleArrowsBlock() {
    this.state.arrowsAreBlocked
      ? this.setState({ arrowsAreBlocked: '' })
      : this.setState({ arrowsAreBlocked: 'slider-block__arrow_blocked' });
  }

  prev() {
    this.slider1.current.slickPrev();
    setTimeout(this.onSlideChange, 1);
  }

  next() {
    this.slider1.current.slickNext();
    setTimeout(this.onSlideChange, 1);
  }

  render() {
    const settings1 = {
      //afterChange: () => console.log(this.dots.current),
      /* appendDots: (dots) => (
        <ul onClick={() => console.log(22)} ref={this.dots}>
          {dots}
        </ul>
      ), */
      arrows: false,
      asNavFor: this.state.nav2,
      afterChange: () => this.toggleArrowsBlock(),
      beforeChange: () => this.toggleArrowsBlock(),
      centerMode: true,
      dots: true,
      infinite: true,
      initialSlide:
        this.pages.indexOf(this.path.replace(/\//g, '')) > -1
          ? this.pages.indexOf(this.path.replace(/\//g, ''))
          : 0,
      onSwipe: () => setTimeout(this.onSlideChange, 1),
      ref: this.slider1,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: this.state.vw < 769,
      variableWidth: true
    };

    const settings2 = {
      arrows: false,
      asNavFor: this.state.nav1,
      centerMode: true,
      infinite: true,
      initialSlide:
        this.pages.indexOf(this.path.replace(/\//g, '')) > -1
          ? this.pages.indexOf(this.path.replace(/\//g, ''))
          : 0,
      ref: this.slider2,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      variableWidth: true
    };

    return (
      <section className="slider-block">
        <div
          className={`slider-block__slider slider-block__slider_${this.state.current}`}>
          {this.content.slides.map((slide, i) => (
            <div
              className={`slider-block__background slider-block__background_${
                slide.name
              } ${
                slide.name === this.state.current &&
                'slider-block__background_active'
              }`}
              key={i}
            />
          ))}
          <div className="slider-block__wrapper">
            <Slider {...settings1}>
              {this.content.slides.map((slide) => (
                <div
                  className={`slider-block__image slider-block__image_${slide.name}`}
                  key={slide.name}
                  data-page={slide.name}>
                  <h2
                    className="slider-block__image-header"
                    dangerouslySetInnerHTML={{
                      __html: slide.header
                    }}
                  />
                  <div className="slider-block__image-container">
                    <img src={slide.image} alt={slide.name} />
                  </div>
                </div>
              ))}
            </Slider>
            <PrevArrow
              prev={this.prev}
              isBlocked={this.state.arrowsAreBlocked}
            />
            <NextArrow
              next={this.next}
              isBlocked={this.state.arrowsAreBlocked}
            />
          </div>
        </div>

        <div
          className={`slider-block__country slider-block__country_${this.state.current}`}>
          <div className="slider-block__wrapper slider-block__wrapper_2">
            <Slider {...settings2}>
              {this.content.slides.map((slide) => (
                <div className="slider-block__text-block" key={slide.name}>
                  <h2
                    className="slider-block__header"
                    dangerouslySetInnerHTML={{
                      __html: slide.header
                    }}
                  />
                  <p
                    className="slider-block__text"
                    dangerouslySetInnerHTML={{
                      __html: slide.text
                    }}
                  />
                  <a
                    className="slider-block__ivi"
                    href={slide.link}
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="slider-block__ivi-text">
                      Смотреть бесплатно на
                    </span>
                    <span className="slider-block__ivi-image">
                      <img src={this.content.ivi} alt="Ivi" />
                    </span>
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default SliderBlock;

function PrevArrow({ prev, isBlocked }) {
  return (
    <div
      className={`slider-block__arrow slider-block__arrow_prev ${isBlocked}`}>
      <div className="slider-block__arrow-btn" onClick={prev}>
        <div className="slider-block__arrow-btn-background slider-block__arrow-btn-background_prev" />
        <div className="slider-block__arrow-btn-inner" />
      </div>{' '}
    </div>
  );
}

function NextArrow({ next, isBlocked }) {
  return (
    <div
      className={`slider-block__arrow slider-block__arrow_next ${isBlocked}`}>
      <div className="slider-block__arrow-btn" onClick={next}>
        <div className="slider-block__arrow-btn-background slider-block__arrow-btn-background_next" />
        <div className="slider-block__arrow-btn-inner" />
      </div>
    </div>
  );
}
