import React from 'react';
import './CiteBlock.scss';

function CiteBlock({ text, source }) {
  return (
    <div className="cite-block">
      <q
        className="cite-block__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <cite
        className="cite-block__source"
        dangerouslySetInnerHTML={{ __html: source }}
      />
    </div>
  );
}

export default CiteBlock;
