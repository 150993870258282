import React from 'react';
import InfoBlock from '../includes/InfoBlock/InfoBlock';
import CiteBlock from '../includes/CiteBlock/CiteBlock';

function Addition({ content }) {
  return (
    <section className="addition">
      <h2 className="addition__header">{content.header}</h2>
      <div className="addition__image">
        <img src={content.image} alt="What to add" />
      </div>
      <div className="addition__text-block">
        <p
          className="addition__text"
          dangerouslySetInnerHTML={{ __html: content.text[0] }}
        />
        {content.list && (
          <ul className="addition__list">
            {content.list.map((item, i) => (
              <li
                dangerouslySetInnerHTML={{ __html: item }}
                key={i}
                className="addition__list-item"
              />
            ))}
          </ul>
        )}
        {content.text[1] && (
          <p
            className="addition__text"
            dangerouslySetInnerHTML={{ __html: content.text[1] }}
          />
        )}
        {content.citation && (
          <CiteBlock
            source={content.citation.source}
            text={content.citation.text}
          />
        )}
      </div>
      {content.backgroundImage && (
        <div className="addition__background-image">
          <img src={content.backgroundImage} alt="Addition background" />
        </div>
      )}
      <InfoBlock type={content.infoBlock.type} text={content.infoBlock.text} />
    </section>
  );
}

export default Addition;
