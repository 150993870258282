import React from 'react';

function Ingredients({ content }) {
  return (
    <section className="ingredients">
      <h2 className="ingredients__header">Ингредиенты</h2>
      <div className="ingredients__list">
        <div className="ingredients__list-block">
          {content.list.map((item, i) => {
            return i < 4 ? (
              <div
                className={`ingredients__list-item ingredients__list-item_${
                  i + 1
                }`}
                key={i}>
                <img
                  className="ingredients__picture ingredients__picture_desk"
                  src={item.image}
                  alt="Ingredient"
                />
                <img
                  className="ingredients__picture ingredients__picture_mob"
                  src={item.imageMob}
                  alt="Ingredient"
                />
              </div>
            ) : null;
          })}
        </div>
        <div className="ingredients__list-block">
          {content.list.map((item, i) => {
            return i >= 4 ? (
              <div
                className={`ingredients__list-item ingredients__list-item_${
                  i + 1
                }`}
                key={i}>
                <img
                  className="ingredients__picture ingredients__picture_desk"
                  src={item.image}
                  alt="Ingredient"
                />
                <img
                  className="ingredients__picture ingredients__picture_mob"
                  src={item.imageMob}
                  alt="Ingredient"
                />
              </div>
            ) : null;
          })}
        </div>
      </div>
    </section>
  );
}

export default Ingredients;
