import React from 'react';
import iviRed from '../../assets/images/common/ivi-red.svg';
import InfoBlock from '../includes/InfoBlock/InfoBlock';

function Movies({ content }) {
  return (
    <section className="movies">
      <div className="movies__content-block">
        <h2 className="movies__header">
          {content.header}кино{' '}
          <span className="movies__header-ivi">
            вместе&nbsp;
            <span className="movies__header-ivi-mobile">
              с&nbsp;&nbsp;
              <span className="movies__header-ivi-image">
                <img src={iviRed} alt="Ivi" />
              </span>
            </span>
          </span>
        </h2>
        <div className="movies__image movies__image_mobile">
          <img src={content.image} alt="Laptop" />
        </div>
        <p
          className="movies__text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <a
          className="movies__link"
          href={content.link}
          target="_blank"
          rel="noopener noreferrer">
          СМОТРЕТЬ
        </a>
      </div>
      <div className="movies__image movies__image_desktop">
        <img src={content.image} alt="Laptop" />
      </div>
      <InfoBlock type="interest" text={content.infoBlock} />
    </section>
  );
}

export default Movies;
