import carrotWok from './carrot-wok.png';
import carrotSabdgi from './carrot-sabdgi.png';
import carrotMexican from './carrot-mexican.png';
import carrot_mob from './carrot-mob.png';
import cauliflower from './cauliflower.png';
import cauliflower_mob from './cauliflower-mob.png';
import ginger from './ginger.png';
import ginger_mob from './ginger-mob.png';
import greenBeans from './green-beans.png';
import greenBeansMexican from './green-beans-mexican.png';
import greenBeans_mob from './green-beans-mob.png';
import mushrooms from './mushrooms.png';
import mushrooms_mob from './mushrooms-mob.png';
import onion from './onion.png';
import onion_mob from './onion-mob.png';
import sweetPepper from './sweet-pepper.png';
import sweetPepperMexican from './sweet-pepper-mexican.png';
import sweetPepperHawaiian from './sweet-pepper-hawaiian_mob.png';
import sweetPepper_mob from './sweet-pepper-mob.png';
import hotPepper from './hot-pepper.png';
import hotPepper_mob from './hot-pepper-mob.png';
import broccoli from './broccoli.png';
import broccoli_mob from './broccoli-mob.png';
import potato from './potato.png';
import potato_mob from './potato-mob.png';
import rice from './rice.png';
import rice_mob from './rice-mob.png';
import greenPeace from './green-peas.png';
import greenPeaceMexican from './green-peas-mexican.png';
import greenPeace_mob from './green-peas-mob.png';
import corn from './corn.png';
import corn_mob from './corn-mob.png';
import brusselsSprouts from './brussels-sprouts.png';
import brusselsSprouts_mob from './brussels-sprouts-mob.png';
import tomatoes from './tomatoes.png';
import tomatoes_mob from './tomatoes-mob.png';
// ratatui
import ratatuiEggplant from './eggplant.png';
import ratatuiSweetPepper from './ratatui-sweet-pepper.png';
import ratatuiHotPepper from './ratatui-hot-pepper.png';
import ratatuiTomatoes from './ratatui-tomatoes.png';
import ratatuiOnion from './ratatui-onion.png';
import cilantro from './cilantro.png';
import garlic from './garlic.png';
import ratatuiEggplant_mob from './eggplant-mob.png';
import ratatuiSweetPepper_mob from './ratatui-sweet-pepper-mob.png';
import ratatuiHotPepper_mob from './ratatui-hot-pepper-mob.png';
import ratatuiTomatoes_mob from './ratatui-tomatoes-mob.png';
import cilantro_mob from './cilantro-mob.png';
import zucchini from './ratatui-zucchini.png';
import zucchini_mob from './ratatui-zucchini-mob.png';
import grilPepper from './gril-pepper.png';
import grilPepper_mob from './gril-pepper_mob.png';
// fasulie
import fasulie_cauliflower from './fasulie-cauliflower.png';
import fasulie_greenBeans from './fasulie-green-beans.png';
import fasulie_greenBeans_mob from './fasulie-green-beans-mob.png';
import fasulie_cilantro from './fasulie-cilantro.png';
import fasulie_cilantro_mob from './fasulie-cilantro-mob.png';
import fasulie_hotPepper from './fasulie-hot-pepper.png';
import fasulie_hotPepper_mob from './fasulie-hot-pepper-mob.png';
import fasulie_broccoli from './fasulie-broccoli.png';
import fasulie_broccoli_mob from './fasulie-broccoli-mob.png';
import fasulie_greenPeace from './fasulie-green-peas.png';
import fasulie_greenPeace_mob from './fasulie-green-peas-mob.png';

export default {
  carrotWok,
  carrotSabdgi,
  carrotMexican,
  carrot_mob,
  cauliflower,
  cauliflower_mob,
  ginger,
  ginger_mob,
  greenBeans,
  greenBeans_mob,
  greenBeansMexican,
  mushrooms,
  mushrooms_mob,
  onion,
  onion_mob,
  sweetPepper,
  sweetPepper_mob,
  sweetPepperMexican,
  sweetPepperHawaiian,
  hotPepper,
  hotPepper_mob,
  broccoli,
  broccoli_mob,
  potato,
  potato_mob,
  rice,
  rice_mob,
  greenPeace,
  greenPeaceMexican,
  greenPeace_mob,
  corn,
  corn_mob,
  brusselsSprouts,
  brusselsSprouts_mob,
  tomatoes,
  tomatoes_mob,
  ratatuiEggplant,
  ratatuiSweetPepper,
  ratatuiHotPepper,
  fasulie_hotPepper_mob,
  ratatuiTomatoes,
  ratatuiOnion,
  cilantro,
  garlic,
  zucchini,
  zucchini_mob,
  ratatuiEggplant_mob,
  ratatuiSweetPepper_mob,
  ratatuiHotPepper_mob,
  ratatuiTomatoes_mob,
  cilantro_mob,
  fasulie_broccoli,
  fasulie_cauliflower,
  fasulie_cilantro,
  fasulie_greenBeans,
  fasulie_greenPeace,
  fasulie_hotPepper,
  fasulie_greenBeans_mob,
  fasulie_cilantro_mob,
  fasulie_broccoli_mob,
  fasulie_greenPeace_mob,
  grilPepper,
  grilPepper_mob
};
