import React from 'react';
import './InfoBlock.scss';

function InfoBlock({ type, text }) {
  return (
    <div className="info-block">
      {type === 'question' ? (
        <h3 className="info-block__header">
          <span className="info-block__icon info-block__icon_1">?</span>
          Знаете ли вы, что...
        </h3>
      ) : (
        <h3 className="info-block__header">
          <span className="info-block__icon info-block__icon_2">!</span>
          Это интересно:
        </h3>
      )}
      <p
        className="info-block__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}

export default InfoBlock;
