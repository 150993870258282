import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import scrollToElem from '../../../polyfills';
import './Production.scss';

class ProductionDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.content = this.props.content;
    this.history = this.props.history;
    this.path = this.props.history.location.pathname;

    this.state = {
      current: null,
      content: null
    };

    this.historyListen = this.historyListen.bind(this);
  }

  componentDidMount() {
    const currentPath = this.props.history.location.pathname;
    this.setState(
      { current: currentPath.replace(/\//g, '') },
      this.filterContent
    );
    this.historyListen();
  }

  filterContent() {
    let currentItem;
    const newContent = this.content.filter((item) => {
      if (item.id !== this.state.current) {
        return true;
      } else currentItem = item;
    });
    newContent.unshift(currentItem);

    this.setState({ content: newContent });
  }

  historyListen() {
    this.history.listen(() => {
      const currentPath = this.props.history.location.pathname;
      this.setState({ current: currentPath.replace(/\//g, '') }, () => {
        this.filterContent();
        scrollToElem('.header');
      });
    });
  }

  render() {
    const content = this.state.content || this.content;

    return (
      <ul className="production__list production__list_desktop">
        {content.map((item) => (
          <li className="production__list-item" key={item.id}>
            <Link to={`/${item.id}`} className="production__link" />
            <div className="production__image">
              <img src={item.image} alt="Product" />
            </div>
            <h3
              className="production__subheader"
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <p
              className="production__description"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </li>
        ))}
      </ul>
    );
  }
}

class ProductionMobile extends React.Component {
  constructor(props) {
    super(props);
    this.content = this.props.content;
    this.history = this.props.history;
    this.path = this.props.history.location.pathname;
    this.pages = this.props.content.map((item) => item.id);

    this.state = {
      current: null
    };

    this.slider = React.createRef();

    this.historyListen = this.historyListen.bind(this);
    this.onSlideChange = this.onSlideChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      current: document
        .querySelector('.slick-center .slider-block__image')
        .getAttribute('data-page')
    });

    this.historyListen();
  }

  onSlideChange() {
    const newPage = document
      .querySelector('.slick-center .production__list-item')
      .getAttribute('data-page');
    this.setState({
      current: newPage
    });
    setTimeout(() => this.history.push(`/${newPage}`), 500);
  }

  historyListen() {
    this.history.listen(() => {
      const currentPath = this.props.history.location.pathname;
      const currentPage = this.state.current;
      const slideNumber = this.pages.indexOf(currentPath.replace(/\//g, ''));
      if (currentPage && `/${currentPage}` !== currentPath) {
        this.setState({ current: currentPath.replace(/\//g, '') });
        this.slider.current.slickGoTo(slideNumber);
      }
    });
  }

  render() {
    this.settings = {
      arrows: false,
      centerMode: true,
      dots: true,
      infinite: true,
      initialSlide:
        this.pages.indexOf(this.path.replace(/\//g, '')) > -1
          ? this.pages.indexOf(this.path.replace(/\//g, ''))
          : 0,
      onSwipe: () => setTimeout(this.onSlideChange, 1),
      ref: this.slider,
      slide: 'li',
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true
    };

    return (
      <ul className="production__list production__list_mobile">
        <Slider {...this.settings}>
          {this.content.map((item) => (
            <li
              className="production__list-item"
              key={item.id}
              data-page={`${item.id}`}>
              <Link to={`/${item.id}`} className="production__link" />
              <div className="production__image">
                <img src={item.image} alt="Product" />
              </div>
              <h3
                className="production__subheader"
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <p
                className="production__description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </li>
          ))}
        </Slider>
      </ul>
    );
  }
}

function Production({ content, history }) {
  return (
    <section className="production">
      <div className="production__wrapper">
        <h2 className="production__header">Готовьте на всех языках!</h2>
        <ProductionDesktop content={content} history={history} />
        <ProductionMobile content={content} history={history} />
      </div>
    </section>
  );
}

export default Production;
