import product from './product.png';
import dish_1 from './dish-1.png';
import dish_2 from './dish-2.png';
import serving from './serving.jpg';
import servingBackground from './serving-background.png';
import addition from './addition.png';
import travel from './travel.jpg';
import travel_mob from './travel-mob.jpg';
import movies from './movies.png';

export default {
  product,
  dish_1,
  dish_2,
  serving,
  servingBackground,
  addition,
  travel,
  travel_mob,
  movies
};
