import wok from './wok.png';
import fasulie from './fasulie.png';
import hawaiian from './hawaiian.png';
import mexican from './mexican.png';
import ratatui from './ratatui.png';
import sabdgi from './sabdgi.png';
import ivi from './ivi.svg';
import logo from './logo.png';
import fb from './fb.svg';
import ytb from './ytb.svg';
import ok from './ok.svg';
import vk from './vk.svg';
import inst from './inst.svg';
import ogImage from './og-image.png';
import mix from './mix.png';

export default {
  wok,
  fasulie,
  hawaiian,
  mexican,
  ratatui,
  sabdgi,
  ivi,
  logo,
  fb,
  ytb,
  ok,
  vk,
  inst,
  ogImage,
  mix
};
