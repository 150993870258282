import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
import Header from './components/includes/Header/Header';
import SliderBlock from './components/includes/Slider/SliderBlock';
import content, { sliderContent, productionContent } from './content';
import Wok from './components/pages/Wok/Wok';
import Sabdgi from './components/pages/Sabdgi/Sabdgi';
import Hawaiian from './components/pages/Hawaiian/Hawaiian';
import Mexican from './components/pages/Mexican/Mexican';
import Ratatui from './components/pages/Ratatui/Ratatui';
import Fasulie from './components/pages/Fasulie/Fasulie';
import ProductionBlock from './components/includes/Production/ProductionBlock';
import Footer from './components/includes/Footer/Footer';
import './App.scss';

const Slider = withRouter(SliderBlock);
const Production = withRouter(ProductionBlock);

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Slider content={sliderContent} />
        <div className="app__wrapper">
          <Switch>
            <Route path="/wok">
              <div className="app__background">
                <div className="app__background-container app__background-container_wok" />
              </div>
              <Wok content={content.pages.wok} />
            </Route>
            <Route path="/sabdgi">
              <div className="app__background">
                <div className="app__background-container app__background-container_sabdgi" />
              </div>
              <Sabdgi content={content.pages.sabdgi} />
            </Route>
            <Route path="/hawaiian">
              <div className="app__background">
                <div className="app__background-container app__background-container_hawaiian" />
              </div>
              <Hawaiian content={content.pages.hawaiian} />
            </Route>
            <Route path="/mexican">
              <div className="app__background">
                <div className="app__background-container app__background-container_mexican" />
              </div>
              <Mexican content={content.pages.mexican} />
            </Route>
            <Route path="/ratatui">
              <div className="app__background">
                <div className="app__background-container app__background-container_ratatui" />
              </div>
              <Ratatui content={content.pages.ratatui} />
            </Route>
            <Route path="/fasulie">
              <div className="app__background">
                <div className="app__background-container app__background-container_fasulie" />
              </div>
              <Fasulie content={content.pages.fasulie} />
            </Route>
            <Route path="/">
              <Redirect to="/wok" />
            </Route>
          </Switch>
        </div>
        <Production content={productionContent} />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
