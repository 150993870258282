import React from 'react';
import InfoBlock from '../includes/InfoBlock/InfoBlock';

function Serving({ content }) {
  return (
    <section className="serving">
      <h2 className="serving__header">{content.header}</h2>
      <div className="serving__content">
        <p
          className="serving__text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <div className="serving__image">
          <div className="serving__image-container">
            <img src={content.image} alt="How to serve" />
          </div>
        </div>
      </div>
      <div className="serving__background-image">
        <img src={content.backgroundImage} alt="Serving background" />
      </div>
      <InfoBlock type={content.infoBlock.type} text={content.infoBlock.text} />
    </section>
  );
}

export default Serving;
