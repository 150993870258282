import product from './product.png';
import dish_1 from './dish-1.png';
import dish_2 from './dish-2.png';
import serving from './serving.png';
import servingBackground from './serving-background.png';
import addition from './addition.jpg';
import additionBackground from './addition-background.png';
import umbrella from './umbrella.png';
import travel from './travel.jpg';
import movies from './movies.png';

export default {
  product,
  dish_1,
  dish_2,
  serving,
  servingBackground,
  addition,
  additionBackground,
  umbrella,
  travel,
  movies
};
