import React from 'react';
import InfoBlock from '../includes/InfoBlock/InfoBlock';
import CiteBlock from '../includes/CiteBlock/CiteBlock';

function Product({ content }) {
  return (
    <section className="product">
      <div className="product__text-block">
        <h1 className="product__header product__header_desk">
          {content.header}
        </h1>
        <p
          className="product__text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <CiteBlock
          source={content.citation.source}
          text={content.citation.text}
        />
      </div>
      <div className="product__image">
        <img src={content.image} alt="Fasulie classic" />
      </div>
      <h1 className="product__header product__header_mob">{content.header}</h1>
      {content.infoBlock && (
        <InfoBlock type="question" text={content.infoBlock} />
      )}
    </section>
  );
}

export default Product;
