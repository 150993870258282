import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfills';
// import device from 'current-device';
import React from 'react';
import { render } from 'react-snapshot';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// console.log('device.mobile() === %s', device.mobile());

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
