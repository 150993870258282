import React from 'react';
import Helmet from 'react-helmet';

import favicon from '../../assets/images/favicon.ico';

function Head({ content }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {content.preload.map((font, i) => (
        <link
          rel="preload"
          href={font}
          as="font"
          crossorigin="anonymous"
          key={i}
        />
      ))}
      <title>{content.title}</title>
      <meta name="description" content={content.description} />
      <link rel="icon" href={favicon} />
      <meta property="og:title" content={content.title} />
      <meta property="og:description" content={content.description} />
      <meta property="og:type" content="food" />
      <meta property="og:image" content={content.ogImage} />
    </Helmet>
  );
}

export default Head;
