import React from 'react';
import InfoBlock from '../includes/InfoBlock/InfoBlock';

function Travel({ content }) {
  return (
    <section className="travel">
      <div className="travel__text-block">
        <h2 className="travel__header">{content.header}</h2>
        <p
          className="travel__text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
      </div>
      {content.image && (
        <div className="travel__image">
          <img src={content.image} alt="Umbrella" />
        </div>
      )}
      <div className="travel__background-image">
        <img
          className="travel__background-image_desk"
          src={content.backgroundImage}
          alt="Travel background"
        />
        {content.backgroundImageMob && (
          <img
            className="travel__background-image_mob"
            src={content.backgroundImageMob}
            alt="Travel background"
          />
        )}
      </div>
      <InfoBlock type={content.infoBlock.type} text={content.infoBlock.text} />
    </section>
  );
}

export default Travel;
