import React from 'react';
import { headerContent } from '../../../content';
import './Header.scss';

function Header() {
  return (
    <header className="header">
      <div className="header__logo">
        <a
          className="header__logo-link"
          href="https://bonduelle.ru/"
          target="_blank"
          rel="noopener noreferrer">
          <img src={headerContent.logo} alt="Logo" />
        </a>
      </div>
      <div className="header__social">
        {headerContent.social.map((item, i) => (
          <a
            className={`header__social-link header__social-link_${item.alt}`}
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            key={i}>
            <img src={item.image} alt={`${item.alt} link`} />
          </a>
        ))}
      </div>
    </header>
  );
}

export default Header;
