import React from 'react';
import Head from '../../templates/Head';
import Product from '../../templates/Product';
import Mix from '../../templates/Mix';
import Ingredients from '../../templates/Ingredients';
import Cooking from '../../templates/Cooking';
import Serving from '../../templates/Serving';
import Addition from '../../templates/Addition';
import Travel from '../../templates/Travel';
import Movies from '../../templates/Movies';

import './Wok.scss';

function Wok({ content }) {
  return (
    <div className="wok">
      <Head content={content.head} />
      <Product content={content.product} />
      <h2 className="wok__offer">
        Готовьте на&nbsp;всех языках с&nbsp;коллекцией замороженных миксов
        от&nbsp;Bonduelle!
      </h2>
      <Mix content={content.mix} />
      <Ingredients content={content.ingredients} />
      <Cooking content={content.cooking} />
      <Serving content={content.serving} />
      <Addition content={content.addition} />
      <Travel content={content.travel} />
      <Movies content={content.movies} />
    </div>
  );
}

export default Wok;
