import React from 'react';

function Cooking({ content }) {
  return (
    <section className="cooking">
      <h2 className="cooking__header cooking__header_mob">{content.header}</h2>
      {content.dishes.map((dish, i) => (
        <div className={`cooking__dish cooking__dish_${i + 1}`} key={i}>
          {i < 1 ? (
            <div className={`cooking__image cooking__image_${i + 1}`}>
              <div className={`cooking__image-container `}>
                <img src={dish.image} alt="Dish" />
              </div>
            </div>
          ) : (
            <div className={`cooking__image cooking__image_${i + 1}`}>
              <img src={dish.image} alt="Dish" />
            </div>
          )}
          <div className={`cooking__text-block cooking__text-block_${i + 1}`}>
            {!i && (
              <h2 className="cooking__header cooking__header_desk">
                {content.header}
              </h2>
            )}
            <h3
              className="cooking__subheader"
              dangerouslySetInnerHTML={{ __html: dish.subheader }}
            />
            <p
              className="cooking__description"
              dangerouslySetInnerHTML={{ __html: dish.description }}
            />
            <div className={`cooking__recipe cooking__recipe_${i + 1}`}>
              <ul className="cooking__recipe-list">
                {dish.ingredients.map((item, i) => (
                  <li
                    className="cooking__recipe-list-item"
                    dangerouslySetInnerHTML={{ __html: item }}
                    key={i}
                  />
                ))}
              </ul>
              <div
                className={`cooking__recipe-block cooking__recipe-block_${
                  i + 1
                }`}>
                <p
                  className="cooking__recipe-text"
                  dangerouslySetInnerHTML={{ __html: dish.text }}
                />
                <a className="cooking__recipe-link" href={dish.link}>
                  РЕЦЕПТ
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Cooking;
