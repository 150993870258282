import React from 'react';

function Mix({ content }) {
  return (
    <section className="mix">
      <div className="mix__image">
        <img src={content.image} alt="Mix" />
      </div>
      <div className="mix__description">
        <h2 className="mix__header">{content.header}</h2>
        <p
          className="mix__text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
        <div className="mix__calorie">
          <h3 className="mix__calorie-subheader">
            Пищевая и&nbsp;энергетическая ценность на&nbsp;100&nbsp;г:
          </h3>
          <ul className="mix__list">
            {Object.values(content.calorie).map((item, i) => (
              <li className="mix__list-item" key={i}>
                <span>
                  <span className="mix__list-text_amount">{item[0]}</span>
                  {item.length > 2 && (
                    <span className="mix__list-text_measure">{item[1]}</span>
                  )}
                </span>
                <span className="mix__list-text_measure">
                  {item[item.length - 1]}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Mix;
